//Light Theme Colors
$light-primary-300: #FFCAC7;
$light-primary-400: #FF958F;
$light-primary-450: #FF6565;
$light-primary-500: #FF6158;
$light-primary-600: #AA403A;
$light-primary-700: #55201D;

$light-neutral-000: #ffffff;
$light-neutral-001: #FAFAFA;
$light-neutral-009: #DBDBDB;
$light-neutral-010: #818193;
$light-neutral-011: #4D4F5C;
$light-neutral-012: #2C2C3E;
$light-neutral-013: #43425D;
$light-neutral-014: #D5D5D5;
$light-neutral-015: #A1A1A1;
$light-neutral-016: #F1F1F3;
$light-neutral-017: #707070;
$light-neutral-018: #3b3b53;
$light-neutral-019: #808192;
$light-neutral-020: #000000;

$light-error-500: #FF0000;

//Dark Theme Colors
$dark-primary-500: #e0e0e0;
$dark-primary-600: #c2c2c2;

//Font Weight
$font-weight-thin: 100;
$font-weight-exlight: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-exbold: 800;
$font-weight-black: 900;

//Widths
$container-width-010: 250px;

//Paddings
$container-padding: 20px;