@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"),
    url(../fonts/ProximaNovaAltThin.otf) format("truetype");
}
@font-face {
  font-family: "Proxima";
  src: local("Proxima"), url(../fonts/ProximaNovaAltBold.otf) format("truetype");
}

*:focus {
  outline: none;
}
.container-header-google {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 35vh;
  /* margin-bottom: 25px; */
}
p {
  color: #4d5055;
  margin-top: 30px;
  font-family: 18vw, "Proxima Nova";
  font-size: 1.5vw;
}
.container-form-google {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  border-color: brown;
  border: 0.5px solid #dadada;
  width: 40vw;
}

.txt-mail-info {
  color: #4d5055;
  align-self: flex-start;
  margin-bottom: 10px;
  margin-left: 5vw;
  margin-top: 30px;
  font-family: 14vw, "Proxima Nova";
  font-size: 1.2vw;
}
.btn-commencer {
  cursor: pointer;
  align-self: start;
  margin-top: 30px;
  margin-left: 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00a2ff;
  width: 10vw;
  height: 6vh;
}

.container-email {
  display: block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #dadada;
  height: 8vh;
  width: 30vw;
}
.txt-email {
  margin-left: 10px;
  align-self: baseline;
  border-width: 0px;
  border: none;
  width: 28vw;
  height: 7vh;
}
.txt-with-acount {
  margin-top: 30px;
  color: #4d5055;
  align-self: flex-start;
  margin-left: 5vw;
  font-family: 14px, "Proxima Nova";
  font-size: 1.2vw;
  margin-bottom: 40px;
}
.navig-create-acount-google {
  color: #00a2ff;
  font-family: 14px, "Proxima Nova";
  font-size: 14px;
  margin: 5px;
  cursor: pointer;
}
.txt-mm {
  font-family: 14px, "Proxima Nova";
  font-size: 14px;
}
.title-langue {
  color: #00a2ff;
  font-family: 14px, "Proxima Nova";
  font-size: 14px;
}
.txt-commencer {
  font-family: 14vw, "Proxima Nova";
  font-size: 1vw;
  color: white;
  font-weight: 400;
}
.container-header-footer {
  display: flex;
  justify-content: center;
  margin-top: 5vh;
  height: 30vh;
}
.icon-rounded-logo {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  resize: vertical;
  background-color: red;
}
.title-login-google {
  margin-top: 2vh;
  color: #4d5055;
  font-family: 14px, "Proxima Nova";
  font-size: 18px;
}
.not-me {
  color: #00a2ff;
  font-family: 14px, "Proxima Nova";
  font-size: 14px;
  margin-bottom: 10px;
  cursor: pointer;
}
