@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"),
    url(../fonts/ProximaNovaAltThin.otf) format("truetype");
}
@font-face {
  font-family: "Proxima";
  src: local("Proxima"), url(../fonts/ProximaNovaAltBold.otf) format("truetype");
}

.profil-body {
  width: 80%;
  margin: auto;
  margin-top: 15px;
  padding: 5px;
  position : relative;
}


.avatar-container{
  margin:5px;
}
.container-profil .avatar-container img{
  height: 100px;
}

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.controls {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 80px;
  display: flex;
  align-items: center;
}
.timezone-selector{
  width: 100%;
}

.cropper-container {
  height: 400px !important;
 }
.acount-button-wrapper {
  background: white;
  z-index: 9999;
  padding: 25px 25px 0px 25px;
}
.acount-button-wrapper button{
  margin : 5px;
}
.profil-picture {
  text-align: center;
}

.container-profil .MuiButton-root {
  padding: 10px 24px;
  font-size: 0.6em;
  min-width: 100px;
  text-transform: none;
  margin : 10px;

}
.timezone-dropdown ul.jsx-4179805763 {
  z-index: 999;
  background: white;
}


.container-profil .MuiAvatar-root {
  width: 200px !important;
  height: 200px !important;
  text-align: center;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 25px;
}
.compte-infos {
  margin-top: 15px;
}
.compte-infos label{
  font-weight: 600;
  margin-top: 10px;
}
.logout-btn{
  position: absolute !important;
  right: -5px !important;
} 