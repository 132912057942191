*:focus {
  outline: none;
  outline-width: 0 !important;
}

.container-root {
  display: flex;
  flex-direction: column;
}
.header-container {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 13vh;
  width: 100vw;
}
.header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  background-color: #393034;
  color: white;
  font-weight: 600;
  font-family: "Proxima Nova";
  width: 100vw;
}

.img-container {
  margin-right: 32vw;
}
.icon-calendar {
  width: 50px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  resize: vertical;
}
.menu-container {
  width: 25vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.menu-one {
  cursor: pointer;
  flex-direction: row;
}
.menu-two {
  cursor: pointer;
}
.menu-three {
  display: inline-block;
  cursor: pointer;
}
.menu-four {
  display: inline-block;
  cursor: pointer;
}
.dropbtn {
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  margin-top: 12px;
  display: none;
  position: absolute;
  right: 0;
  width: 18vw;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 15px;
  font-family: "Proxima Nova";
  font-size: 15px;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}
.dropdown:hover .dropdown-content {
  display: block;
}
/* .dropdown:hover .dropbtn {
  background-color: white;
} */
.body-header {
  position: relative;
  box-shadow: 1px 1px 5px #7c7c7c91;
  padding: 0 4vw;
}
.body-container {
  display: flex;
  flex-direction: column;
}
.txt-title {
  font-family: "Proxima Nova";
  font-weight: bold;
  color: #4d5055;
  font-size: 22px;
  margin-left: 23vw;
  margin-right: 9.5vw;
}
.tab-view {
  display: flex;
  flex-direction: row;
  margin-left: 2vw;
}

.txt-tab {
  margin-top: 2vh;
  font-family: "Proxima Nova";
  font-size: 18px;
  font-weight: bold;
  color: #4d5055;
  margin-left: 15px;
  margin-bottom: 2vh;
  margin-right: 5vw;
}
.txt-tab_event {
  margin-top: 2vh;
  font-family: "Proxima Nova";
  font-size: 16px;
  font-weight: bold;
  color: #4d5055;
  margin-bottom: 2vh;
}
.container-email-accueil {
  background-color: #f9f9f9;
}
.txt-email-accueil {
  background-color: #f9f9f9;
  margin-left: 10px;
  margin-bottom: 0;
  align-self: baseline;
  border-width: 0px;
  border: none;
  font-family: 14px, "Proxima Nova";
  font-size: 18px;
}
.tab_type_bar_bottom_events {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info-user {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 1.5vh;
}
.container-info-accueil {
  display: flex;
  flex: 5;
  margin-left: 1vw;
  flex-direction: column;
}
.tab_type_bar_bottom {
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
}
.txt-name-user {
  font-family: 14px, "Proxima Nova";
  font-size: small;
  color: #4d5055;
}
.txt-url {
  cursor: pointer;
  font-family: 20px, "Proxima Nova";
  font-size: small;
  color: #00a2ff;
}
.containadd {
  border: 1px solid #00a2ff;
  background: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  height: 5vh;
  width: 18vw;
}
.left-btn {
  justify-content: center;
  cursor: pointer;
  align-items: center;
  color: #00a2ff;
}
.txt-new-event {
  font-size: small;
  color: #ffffff;
}
.list-events {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 25px;
  margin-left: 9.5vw;
  align-content: flex-start;
}
.items-one {
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.items-one:hover {
  transform: scale(1.05);
  box-shadow: 0.5px 0.5px 2px white;
}

.row-param {
  display: flex;
  flex-direction: row;
}
.ckeck-class {
  flex: 6;
}
.settings-class {
  flex: 1;
  align-self: flex-end;
}

.txt-reunion {
  font-family: 20px, "Proxima Nova";
  font-size: 20px;
  font-weight: 500;
  color: #4d5055;
}
.txt-event-info {
  justify-content: center;
  margin-left: 15px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: "1vh";
}

.heure-info {
  font-family: 20px, "Proxima Nova";
  font-size: 15px;
  color: #4d5055;
  align-self: flex-start;
}

.info-lien {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  border-top: solid 1px #cecece;
  padding: 15px;
}
.txt-min {
  font-size: 15px;
  color: #00a2ff;
}
.btn-event {
  display: flex;

  flex-direction: row;
}
.copy-link {
  padding: 4px;
  border-top: 1px solid #00a2ff;
  border-left: 1px solid #00a2ff;
  border-bottom: 1px solid #00a2ff;

  border-top-left-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
  color: #00a2ff;
  font-family: 20px, "Proxima Nova";
  font-size: 14px;
}
.icon-show {
  border: 1px solid #00a2ff;
  border-top-left-radius: 5px;
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #00a2ff;
  font-family: 20px, "Proxima Nova";
  font-size: 18px;
}
.tab-list {
  display: none;
  justify-content: center;
  align-items: center;
}
.tab-list.active {
  display: block;
}
.txt-tab.active::after {
  content: " ";
  height: 4px;
  background: #00a2ff;
  width: 14vw;
  position: absolute;
  z-index: 999;
  margin-top: 40px;
  margin-left: -11vw;
}
.no-content-wrapper {
  display: flex;
  flex-direction: column;
  height: 50vh;
  justify-content: center;
  align-items: center;
  margin-top: 4vh;
}

.home-control {
  position: absolute;
  z-index: 9;
  right: 4vw;
  bottom: 15px;
}

.items-one-control .delete-button {
  background: #950f0fa6;
  border: none;
  margin: 2px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: white;
}
.items-one-control .delete-button svg {
  width: 20px !important;
}
.tab_liste {
  width: 80vw;
}
.tab_item {
  font-family: "Proxima Nova";
  font-weight: "bold";
}
@media only screen and (max-width: 600px) {
  .txt-tab {
    font-family: "Proxima Nova";
    font-size: 14px;
    font-weight: bold;
    color: #4d5055;
    margin-bottom: 3vh;
  }
  .txt-tab.active::after {
    content: " ";
    height: 4px;
    background: #00a2ff;
    width: 25vw;
    position: absolute;
    z-index: 999;
    margin-left: -30vw;
  }
  .tab_liste {
    width: 100vw;
  }
}
