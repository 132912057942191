.eventNoteIcon{
    color: #00a2ff;
    margin-top  : 2vh;
    margin-left : 1vw;
}
.event-control-wrapper{
   margin-bottom : 2vh;
   cursor       : pointer;
}
.event-control-item{
    height       : 3vh;
    margin-right : 1vw;
}