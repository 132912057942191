@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"),
    url(../fonts/ProximaNovaAltThin.otf) format("truetype");
}
@font-face {
  font-family: "Proxima";
  src: local("Proxima"), url(../fonts/ProximaNovaAltBold.otf) format("truetype");
}

*:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
.container-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 20vh;
  /* margin-bottom: 25px; */
}
p {
  color: #4d5055;
  margin-top: 30px;
  font-family: 18vw, "Proxima Nova";
  font-size: 1.5vw;
}
.container-form {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  border-color: brown;
  border: 0.5px solid #dadada;
  width: 40vw;
}

.txt-mail-info-login {
  color: #4d5055;
  align-self: flex-start;
  margin-bottom: 10px;
  margin-left: 5vw;
  margin-top: 30px;
  font-family: 14vw, "Proxima Nova";
  font-size: 1.2vw;
}
.txt-mail-info-password {
  color: #4d5055;
  align-self: flex-start;
  margin-bottom: 10px;
  margin-left: 5vw;
  margin-top: 30px;
  font-family: 14vw, "Proxima Nova";
  font-size: 1.2vw;
}
.btn-commencer-login {
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  align-self: start;
  margin-top: 30px;
  margin-left: 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00a2ff;
  width: 10vw;
  height: 6vh;
}

.container-email-login {
  display: block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #dadada;
  height: 8vh;
  width: 30vw;
}
.txt-email {
  margin-left: 10px;
  align-self: baseline;
  border-width: 0px;
  border: none;
  width: 28vw;
  height: 5vh;
}
.txt-with-acount {
  margin-top: 30px;
  color: #4d5055;
  align-self: flex-start;
  margin-left: 5vw;
  font-family: 14px, "Proxima Nova";
  font-size: 1.2vw;
  margin-bottom: 40px;
}
.navig-create-acount {
  color: #00a2ff;
  font-family: 14px, "Proxima Nova";
  font-size: 14px;
  cursor: pointer;
  margin-left: 5px;
}
.txt-mm {
  font-family: 14px, "Proxima Nova";
  font-size: 14px;
}
.title-langue-login {
  align-self: flex-start;
  color: #00a2ff;
  font-family: 14px, "Proxima Nova";
  font-size: 14px;
  cursor: pointer;
}
.txt-commencer {
  font-family: 14vw, "Proxima Nova";
  font-size: 1vw;
  color: white;
  font-weight: 400;
}
.container-header-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 20vw;
}
.icon-rounded-logo {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  resize: vertical;
  background-color: red;
}
.title-login {
  margin-top: 2vh;
}

@media only screen and (max-width: 600px) {
  .container-form {
    width: 80vw;
  }

  .container-email-login {
    display: block;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 0.5px solid #dadada;
    height: 8vh;
    width: 70vw;
  }
  .btn-commencer-login {
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    align-self: start;
    margin-top: 30px;
    margin-left: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00a2ff;
    width: 30vw;
    height: 6vh;
  }
  .txt-commencer-login {
    font-family: 20vw, "Proxima Nova";
    font-size: 4vw !important;
    color: white;
    font-size: medium;
    font-weight: 400;
  }
  .txt-mail-info-login {
    display: flex;
    color: #4d5055;
    margin-bottom: 10px;
    font-family: 14px, "Proxima Nova";
    font-size: 14px;
  }
  .title-login {
    margin-top: 2vh;
    font-size: medium;
  }
  .title-langue-login {
    align-self: flex-start;
    color: #00a2ff;
    font-family: 14px, "Proxima Nova";
    font-size: 16px;
    cursor: pointer;
  }
  .txt-mail-info-password {
    color: #4d5055;
    align-self: flex-start;
    margin-bottom: 10px;
    margin-left: 5vw;
    margin-top: 20px;
    font-family: 14vw, "Proxima Nova";
    font-size: 14px;
  }
  .txt-email {
    margin-left: 0px !important;
    align-self: baseline;
    border-width: 0px;
    border: none;
    width: 68vw !important;
    height: 5vh;
    background: none;
    user-select: none;
  }
  .txt-email-password {
    margin-left: 0px !important;
    align-self: baseline;
    border-width: 0px;
    border: none;
    width: 68vw !important;
    height: 7vh;
  }
  .txt-with-acount {
    margin-top: 30px;
    color: #4d5055;
    align-self: flex-start;
    margin-left: 5vw;
    font-family: 14px, "Proxima Nova";
    font-size: 1.2vw;
    margin-bottom: 20px !important;
  }
}
