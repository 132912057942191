.container-root-form_modif {
  background-color: white;
}

.container-form-create_modif {
  display: flex;
  flex-direction: column;
}
.container-title-liste_modif {
  margin-top: 5vh;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-right: 9.5vw;
}
.container-formulaire_modif {
  flex: 5;
}
.btn-back-form_modif {
  display: flex;
  margin-left: 9.5vw;
  align-self: flex-start;
  border: 1px solid #00a2ff;
  color: #00a2ff;
  width: 8vw;
  height: 7vh;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: row;
}
.txt-add-type_modif {
  font-family: "Proxima Nova";
  font-weight: bold;
  color: #4d5055;
  font-size: 20px;
}
.container-formulaire_modif {
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  border: 1.5px solid #666a73;
  color: black;
  margin-left: 9.5vw;
  margin-right: 9.5vw;
  margin-bottom: 10vh;
}
.content-border_modif {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  flex-direction: row;
}
.colom-row-style_modif {
  margin-top: 20px;
  display: flex;
  flex: 1;
  margin-left: 5vw;
  display: flex;
  justify-content: start;
  flex-direction: row;
  align-items: center;
}
.rounder-bouton_modif {
  margin-right: 5px;
  height: 25px;
  width: 25px;
  background-color: #8989fc;
  border-radius: 50%;
  display: inline-block;
  font-family: "Proxima Nova";
}
.colom-row-style-rigth_modif {
  margin-top: 20px;

  display: flex;
  flex: 1;
  margin-left: 15px;
  display: flex;
  justify-content: end;
  flex-direction: row-reverse;
  align-items: center;
}
.reset-btn_modif {
  margin-right: 35px;
  font-family: "Proxima Nova";
  font-weight: bold;
}
.btn-next_modif {
  display: flex;
  background-color: #00a2ff;
  align-self: flex-start;
  border: 1px solid #00a2ff;
  border-radius: 5px;
  color: white;
  width: 8vw;
  margin-right: 15px;
  height: 6vh;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: row;
}
.line-unde-menu_modif {
  background-color: #f6fafc;
  height: 2px;
  margin-top: 2vh;
}
.container-form-text_modif {
  margin-left: 5vw;
  margin-top: 5vh;
  margin-bottom: 5vh;
  display: flex;
  flex-direction: column;
}
.container-name-event_modif {
  display: block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #dadada;
  height: 8vh;
  width: 30vw;
}
.txt-event_modif {
  margin-left: 10px;
  align-self: baseline;
  border-width: 0px;
  border: none;
  width: 28vw;
  height: 7vh;
  font-family: "Proxima Nova";
  color: #4d5055;
}
.container-name-event-area_modif {
  display: block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #dadada;
  height: 20vh;
  width: 30vw;
}
.txt-event-area_modif {
  margin-left: 10px;
  align-self: baseline;
  border-width: 0px;
  border: none;
  width: 28vw;
  height: 18vh;
  font-family: "Proxima Nova";
  color: #4d5055;
}
.txt-title-name_modif {
  margin-bottom: 10px;
  font-family: "Proxima Nova";
  color: #4d5055;
  font-size: medium;
  font-weight: bold;
}

.txt-title-lieu_modif {
  margin-top: 5vh;
  color: #4d5055;
  font-family: "Proxima Nova";
  font-size: medium;
  font-weight: bold;
}
.txt-title-lieu-type_modif {
  margin-top: 1vh;
  color: #4d5055;
  font-family: "Proxima Nova";
  font-size: medium;
  font-weight: bold;
}
.container-name-event-form-create_modif {
  display: block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #dadada;
  height: 8vh;
  width: 20vw;
}
.txt-event-lien_modif {
  margin-left: 10px;
  align-self: baseline;
  border-width: 0px;
  border: none;
  width: 19vw;
  height: 7vh;
  font-family: "Proxima Nova";
  color: #4d5055;
}
.txt-title-number_modif {
  margin-bottom: 10px;
  font-family: "Proxima Nova";
  color: #4d5055;
  font-size: medium;
  font-weight: bold;
}
.container-name-event-number_modif {
  display: block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #dadada;
  height: 8vh;
  width: 10vw;
}
.txt-event-number_modif {
  margin-left: 10px;
  align-self: baseline;
  border-width: 0px;
  border: none;
  width: 8vw;
  height: 7vh;
  font-family: "Proxima Nova";
  color: #4d5055;
}
