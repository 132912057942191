@import '../variables.scss';
.MuiButton-root {
    padding: 10px 24px;
    font-size: 0.6em;
    min-width: 100px;
    text-transform: none;
    padding: 8px 24px;

    &.button-md {
        width: $container-width-010;
        margin-bottom: 30px;
        
    }

    .MuiButton-label {
        font-size: 15px;
        font-weight: $font-weight-semibold;
    }
}


/* Theming Section */

.light-theme .themed-content {
    .MuiButton-root.MuiButton-outlined {
        background-color: $light-neutral-000;
        color: $light-primary-500;
        border: 1px solid $light-primary-300;
    
        &:hover {
            background-color: $light-neutral-000;
            border: 1px solid $light-primary-500;
        }
    
        &.Mui-disabled {
            color: rgba(0, 0, 0, 0.26);
            background-color: $light-neutral-000;
            border: 1px solid rgba(0, 0, 0, 0.26);
        }
    }
}

