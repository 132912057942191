@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"),
    url(../fonts/ProximaNovaAltThin.otf) format("truetype");
}
@font-face {
  font-family: "Proxima";
  src: local("Proxima"), url(../fonts/ProximaNovaAltBold.otf) format("truetype");
}

*:focus {
  outline: none;
}
.container-password {
  display: flex;
  margin: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* position: absolute;
    top: 50%;
    margin-left: 35%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); */
}
.container-header-password {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 25vh;
  /* margin-bottom: 25px; */
}
p {
  color: #4d5055;
  margin-top: 30px;
  font-family: 18vw, "Proxima Nova";
  font-size: 1.5vw;
}
.container-form-password {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  border-color: brown;
  border: 0.5px solid #dadada;
  width: 40vw;
}

/* .txt-mail-info-password {
  color: #4d5055;
  align-self: flex-start;
  margin-bottom: 10px;
  margin-left: 5vw;
  margin-top: 30px;
  font-family: 14vw, "Proxima Nova";
  font-size: small;
} */
.btn-commencer-password {
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  align-self: start;
  margin-top: 30px;
  margin-left: 5vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00a2ff;
  width: 10vw;
  height: 6vh;
}

.container-email-password {
  display: block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #dadada;
  height: 8vh;
  width: 30vw;
}
.txt-email-password {
  margin-left: 10px;
  align-self: baseline;
  border-width: 0px;
  border: none;
  width: 27vw;
  height: 7vh;
}
.txt-with-acount-password {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  color: #4d5055;
  align-self: flex-start;
  margin-left: 5vw;
  font-family: 14px, "Proxima Nova";
  font-size: 1.2vw;
  margin-bottom: 40px;
}
.navig-create-acount-password {
  color: #00a2ff;
  font-family: 14px, "Proxima Nova";
  font-size: small;
  cursor: pointer;
  margin-left: 5px;
}
.txt-mm-password {
  font-family: 14px, "Proxima Nova";
  font-size: 14px;
}
.title-langue-password {
  cursor: pointer;
  color: #00a2ff;
  font-family: 14px, "Proxima Nova";
  font-size: 14px;
}
.txt-commencer-password {
  font-family: 14vw, "Proxima Nova";
  font-size: 1vw;
  color: white;
  font-weight: 400;
}
.container-header-footer-password {
  display: flex;
  justify-content: center;
  margin-top: 5vh;
  height: 30vh;
}
.icon-rounded-logo-password {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  resize: vertical;
  background-color: red;
}
.title-login-password {
  margin-top: 2vh;
  font-family: 14vw, "Proxima Nova";
  font-size: medium;
}
.container-email-register-password {
  display: block;
  align-self: start;
  margin-left: 5vw;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #dadada;
  height: 8vh;
  width: 28vw;
}

@media only screen and (max-width: 600px) {
  .container-form-password {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-self: center;
    align-items: center;
    border-color: brown;
    border: 0.5px solid #dadada;
    width: 80vw;
  }
  .title-login-password {
    margin-top: 2vh;
    font-family: 12vw, "Proxima Nova";
    font-size: medium;
  }
  .container-email-register-password {
    display: block;
    align-self: start;
    margin-left: 5vw;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 0.5px solid #dadada;
    height: 8vh;
    width: 70vw;
  }
  .btn-commencer-password {
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    align-self: start;
    margin-top: 30px;
    margin-left: 5vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00a2ff;
    width: 30vw;
    height: 5vh;
  }
  .txt-commencer-password {
    font-family: 14vw, "Proxima Nova";
    font-size: medium;
    color: white;
    font-weight: 400;
  }
}
