@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"),
    url(../fonts/ProximaNovaAltThin.otf) format("truetype");
}
@font-face {
  font-family: "Proxima";
  src: local("Proxima"), url(../fonts/ProximaNovaAltBold.otf) format("truetype");
}

/* * {
  font-family: "Proxima Nova";
} */

*:focus {
  outline: none;
}
.container-header-register-mail {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 10vh;
  /* margin-bottom: 25px; */
}
p {
  color: #4d5055;
  margin-top: 30px;
  font-family: 18vw, "Proxima Nova";
  font-size: 1.5vw;
}
.container-form-register {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  border-color: brown;
  border: 0.5px solid #dadada;
  width: 40vw;
  background: white !important;
}

.txt-mail-info-register-for-mail {
  color: #4d5055;
  align-self: flex-start;
  margin-bottom: 10px;
  margin-left: 5vw;
  margin-top: 30px;
  font-family: 14vw, "Proxima Nova";
  font-size: 1.2vw;
}
.btn-commencer-register-email-form-email {
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
  align-self: start;
  margin-top: 30px;
  margin-left: 5vw;
  display: flex;
  background-color: #00a2ff;
  width: 10vw;
  height: 6vh;
}

.container-email-register-form_email {
  display: block;
  flex-direction: row;
  align-items: center;
  border: 0.5px solid #dadada;
  height: 7vh;
  width: 30vw;
  align-self: flex-start;
  margin-left: 10vh;
}
.txt-email-register-email {
  margin-left: 10px;
  align-self: baseline;
  border-width: 0px;
  border: none;
  width: 28vw;
  height: 7vh;
  margin-bottom: 10px;
}
.txt-with-acount {
  margin-top: 30px;
  color: #4d5055;
  align-self: flex-start;
  margin-left: 5vw;
  font-family: 14px, "Proxima Nova";
  font-size: 1.2vw;
  margin-bottom: 40px;
}
.navig-create-acount {
  color: #00a2ff;
  font-family: 14px, "Proxima Nova";
  font-size: 14px;
  cursor: pointer;
  margin-left: 5px;
}
.txt-mm {
  font-family: 14px, "Proxima Nova";
  font-size: 14px;
}
.title-langue {
  color: #00a2ff;
  font-family: 14px, "Proxima Nova";
  font-size: 14px;
}
.txt-commencer-register-form {
  align-self: center;
  font-family: 14vw, "Proxima Nova";
  font-size: 1vw;
  color: white;
  font-weight: 400;
}
.container-header-footer {
  display: flex;
  justify-content: center;
  margin-top: 5vh;
  height: 30vh;
}
.icon-rounded-logo {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  resize: vertical;
  background-color: red;
}
.title-login-register {
  margin-top: 2vh;
  font-family: "Proxima Nova";
  font-size: 18px;
  font-weight: 600;
  margin-top: 2vh;
  color: #4d5055;
}
.compte_existe {
  color: red;
  font-size: 14px;
  margin-top: 50px;
}
.homeDescription.mt-2 p {
  font-size: 14px;
  font-family: 14vw, "Proxima Nova" !important;
  margin-left: 5vw;
  justify-content: baseline;
  align-self: center;
  margin-right: 5vw;
}
.input-control_register_for_mail {
  border: 0.5px solid #dadada;
  min-height: 8vh;
  width: 100% !important;
  padding: 10px;
}
@media only screen and (max-width: 600px) {
  .container-email-register-form_email {
    display: block;
    flex-direction: row;
    align-items: center;
    border: 0.5px solid #dadada;
    height: 8vh;
    width: 72vw;
    align-self: flex-start;
    margin-left: 2vh;
  }
  .btn-commencer-register-email-form-email {
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
    align-self: start;
    margin-top: 30px;
    margin-left: 5vw;
    display: flex;
    background-color: #00a2ff;
    width: 30vw;
    height: 6vh;
  }
  .txt-commencer-register-form {
    align-self: center;
    font-family: 14vw, "Proxima Nova";
    font-size: medium;
    color: white;
    font-weight: 400;
  }
  .txt-mm {
    font-family: 14px, "Proxima Nova";
    font-size: medium;
  }
  .navig-create-acount {
    color: #00a2ff;
    font-family: 14px, "Proxima Nova";
    font-size: medium;
    cursor: pointer;
    margin-left: 5px;
  }
  .title-langue {
    color: #00a2ff;
    font-family: 14px, "Proxima Nova";
    font-size: medium;
  }
  .txt-mail-info-register-for-mail {
    color: #4d5055;
    align-self: flex-start;
    margin-bottom: 10px;
    margin-left: 5vw;
    margin-top: 30px;
    font-family: 14vw, "Proxima Nova";
    font-size: 14px;
  }

  .form-wrapper {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    background: transparent !important;
    height: 75vh;
  }
  .homeDescription.mt-2 p {
    font-size: 14px;
    font-family: 14vw, "Proxima Nova" !important;
    margin-top: 50px;
    margin-bottom: 0px;
  }
}
