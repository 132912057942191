@import '../variables.scss';

/* Common Section */
.app-upload-picture {
    .banner-button {
        margin-bottom: 22px;
    
        .MuiButton-root.MuiButton-outlined {
            padding: 4px 24px;
            height: 35px;
            width: 130px;
        }
    }
    
    .dropzone-container {
        display: none;
    } 
}

// .MuiDialog-container {
//     .MuiButtonBase-root {
//         width: 150px;
//     }
// }


/* Theming Section */
.light-theme .themed-content {
    .app-upload-picture {
        .banner-button {
            .MuiButton-root.MuiButton-outlined {
                color: white;
                background-color: $light-neutral-011;
                border: 1px solid white;
        
                &:hover {
                    background-color: $light-neutral-010;
                    border: 1px solid white;
                }
            }
        }
    }
}