.container-root-form-calendar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.border-view {
  border: 0.5px solid #dadada;
  display: block;
  flex-direction: column;
}
.container-calendar {
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.class_name_header {
  font-family: 20px, "Proxima Nova";
  font-size: 15px;
  color: #4d5055;
  align-self: flex-start;
}
.container-header-calendar {
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 14px, "Proxima Nova";
  font-size: 25px;
  margin-top: 10vh;
}
.container-header-calendar-text {
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 14px, "Proxima Nova";
  font-size: 25px;
  margin-top: 2vh;
}
.duration-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.day_class_name {
  color: #4d5055;
  font-size: 14px;
  margin: 0 auto;
  text-align: center;
  width: "15vw";
  height: "15vh";
  background-color: white;
  cursor: pointer;
}
.day_class_name :hover {
  background-color: #00a2ff;
  /* color: "white"; */
}

.day_class_name_selected {
  color: #4d5055;
  font-size: 14px;
  margin: 0 auto;
  text-align: center;
  width: "15vw";
  height: "15vh";
  cursor: default;
  background-color: #f6f6f6;
}
