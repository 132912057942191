@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"),
    url(../fonts/ProximaNovaAltThin.otf) format("truetype");
}
@font-face {
  font-family: "Proxima";
  src: local("Proxima"), url(../fonts/ProximaNovaAltBold.otf) format("truetype");
}
body {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-txt-title-register {
  font-family: "Proxima Nova";
  font-size: 18px;
  font-weight: 600;
  margin-top: 2vh;
  color: #4d5055;
}
.txt-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 25vh;
}
.container-form-register-register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 0.5px solid #dadada;
  width: 35vw;
  height: 65vh;
}

.txt-commencer-register-complet {
  font-family: 14vw, "Proxima Nova";
  font-size: 14px;
  color: white;
  font-weight: 400;
}

@media only screen and (max-width: 600px) {
  .header-txt-title-register {
    font-family: "Proxima Nova";
    font-size: medium;
    font-weight: 600;
    margin-top: 2vh;
    color: #4d5055;
  }
  .container-form-register-register {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0.5px solid #dadada;
    width: 80vw;
    height: 65vh;
  }
  .container-email-for-register {
    display: block;
    align-self: center;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 0.5px solid #dadada;
    height: 7vh;
    width: 75vw;
  }
  .btn-commencer-register {
    display: flex;
    margin-top: 30px;
    margin-left: 3.5vw;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    background-color: #00a2ff;
    width: 30vw;
    height: 6vh;
  }

  .txt-commencer-register-complet {
    font-family: 14vw, "Proxima Nova";
    font-size: medium;
    color: white;
    font-weight: 400;
  }
}
