@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"),
    url(../fonts/ProximaNovaAltThin.otf) format("truetype");
}
@font-face {
  font-family: "Proxima";
  src: local("Proxima"), url(../fonts/ProximaNovaAltBold.otf) format("truetype");
}
.content-my-event {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fbfcfd;
  width: 100vw;
}
.container-link {
  display: flex;
  flex: 1;
  margin-top: 6vh;
}

.container-float {
  width: 80vw;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.entete {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.name-title {
  color: rgba(77, 80, 85, 0.6);
  align-self: center;
  font-family: 14px, "Proxima Nova";
  margin-bottom: 15px;
  margin-top: 25px;

  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.msg-txt-small {
  color: rgba(77, 80, 85, 0.6);
  font-family: "Proxima Nova";
  font-size: 14px;
  font-weight: 800;
}
.container-list-event-link {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  align-content: center;
  margin-left: 8vw;
  margin-right: 10vw;
}

.one-container-list {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  margin-left: 2vw;
  margin-top: 6vh;
  border: 1px solid transparent;
}

.one-container-list:hover {
  background-color: #ededee;
}

.line-one-in-event {
  height: 0.1px;
  background-color: rgba(77, 80, 85, 0.6);
  box-shadow: 0.5px 0.5px 20px rgba(77, 80, 85, 0.6);

  width: 28vw;
}
.row-event-link {
  display: flex;
  width: 28vw;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}
.round-event {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-left: 15px;
}
.title-event-link {
  color: #4d5055;
  align-self: center;
  font-family: 14px, "Proxima Nova";
  font-weight: 600;
  font-size: 18px;
}
.txt-description-event {
  align-items: flex-end;
  margin-left: 15px;
  margin-top: 4vh;
  margin-bottom: 12vh;
  width: 25vw;
  height: 8vh;
}

@media only screen and (max-width: 600px) {
  .container-list-event-link {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    align-content: center;
    margin-left: 8vw;
    margin-right: 10vw;
  }
  .line-one-in-event {
    height: 0.1px;
    background-color: rgba(77, 80, 85, 0.6);
    box-shadow: 0.5px 0.5px 20px rgba(77, 80, 85, 0.6);
    width: 70vw;
  }
  .row-event-link {
    display: flex;
    width: 70vw;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
  }
  .title-event-link {
    font-size: 14px;
  }
}
