.container-create {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.title-create {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.btn-retour-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}
.title-new {
  flex: auto;
  display: flex;
  justify-content: center;
  margin-top: 5vh;
  font-family: "Proxima Nova";
  margin-right: 15vw;
  font-weight: bold;
  color: #4d5055;
  font-size: 20px;
}
.btn-back {
  display: flex;
  margin-left: 9.5vw;
  align-self: flex-start;
  margin-top: 5vh;
  border: 1px solid #00a2ff;
  color: #00a2ff;
  width: 8vw;
  height: 7vh;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: row;
}
.line-create {
  margin-top: 5vh;
  background-color: #f6fafc;
  width: 100vw;
  height: 2px;
}
.choix-type-groupe {
  display: flex;
  flex-direction: column;
}
.container-choix-one {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 5vh;
}
.create-new-event {
  align-self: flex-start;
  border: 1px solid #00a2ff;
  color: #00a2ff;
  width: 10vw;
  height: 7vh;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: row;
}
.txt-info-center {
  display: flex;
  width: 35vw;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
  flex: 3;
}
.im-container {
  flex: 1;
  margin-left: 20px;
  display: flex;
  justify-content: center;
}
.btn-create {
  border-radius: 5px;
  display: flex;
  margin-right: 10vw;
  margin-left: 9.5vw;
  align-self: flex-start;
  border: 1px solid #00a2ff;
  background-color: #00a2ff;
  color: white;
  width: 5vw;
  height: 7vh;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-direction: row;
  font-family: "Proxima Nova";
  font-weight: bold;
}
.container-choix-two {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 10vh;
}
.groupe-image {
  display: flex;
  flex: 2;
  flex-direction: row;
  justify-content: space-around;
}
.txt-type {
  font-family: "Proxima Nova";
  font-weight: bold;
  font-size: 22px;
}
.description-Groupe {
  font-family: "Proxima Nova";
  font-weight: 500;
  font-size: 15px;
}
