@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"),
    url(../fonts/ProximaNovaAltThin.otf) format("truetype");
}
@font-face {
  font-family: "Proxima";
  src: local("Proxima"), url(../fonts/ProximaNovaAltBold.otf) format("truetype");
}
/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.container-select-event {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fbfcfd;
  width: 100vw;
  overflow: hidden !important;
}
.container-selecter {
  display: flex;
  flex: 1;
  margin-top: 9vh;
  justify-content: center;
  align-self: center;
}
.content-select-calendar {
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
}
.menu-left {
  display: flex;
  flex: 1;
  flex-direction: column;
  border-right: 0.1px solid #ededee;
  margin-top: 2vh;
  margin-left: 2vw;
}

.btn-rounder-back {
  height: 40px;
  width: 40px;
  background-color: white;
  border: 1px solid #ededee;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.txt-name-selected {
  margin-top: 2vh;
  color: rgba(77, 80, 85, 0.6);
  font-family: 14px, "Proxima Nova";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.txt-event-type-selected {
  margin-top: 2vh;
  color: #4d5055;
  font-family: 14px, "Proxima Nova";
  font-size: 30px;
  font-weight: 600;
  line-height: 24px;
}
.style_box {
  width: "80vw";
  border-radius: "5px";
  overflow-y: "hidden";
  overflow-x: "hidden";
}
.hour-selected {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
}
.hour-event {
  margin-top: 15px;
  margin-left: 10px;
  color: #949699;
  font-family: 14px, "Proxima Nova";
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}
.txt-selected-name {
  margin-top: 25px;
  margin-left: 10px;
  color: #4d5055;
  font-family: 14px, "Proxima Nova";
  font-size: 20px;
  font-weight: 250;
  line-height: 24px;
}
.menu-rigth {
  flex: 2;
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 80vh;
}
.txt-date-selected {
  margin-top: 25px;
  margin-left: 25px;
  align-self: center;
  color: #4d5055;
  font-family: 14px, "Proxima Nova";
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
.c1 {
  padding: 30px;
  margin-left: 25px;
  margin-top: 5px;
  font-size: 16px;
  font-family: 14px, "Proxima Nova";
  color: #4d5055;
}
div .react-calendar {
  border: 1px solid white;
  color: #4d5055;
  font-size: small;
  width: 30vw;
  margin: 0;
}

.c1 .react-calendar__tile {
  font-size: medium;
  font-weight: bold;
  border-radius: 1px;
  margin-bottom: 15px;
  color: #9c9da0;
  background-color: #daf1ff;
}

.c1 .react-calendar__navigation__label {
  pointer-events: none;
  cursor: default;
  color: #9c9da0;
  font-size: 16px;
  font-family: 14px, "Proxima Nova";
}
.c1 .react-calendar__navigation__next2-button {
  display: none;
}
.c1 .react-calendar__navigation__prev2-button {
  display: none;
}

.c1 .react-calendar__tile--now {
  background-color: #daf1ff;
  color: #4dbaff;
}

.c1 .react-calendar__month-view__days__day--weekend {
  color: #9c9da0;
  background-color: #daf1ff;
}

.c1 .react-calendar__tile :hover {
  font-size: 22px;
  color: #22a2ff;
}

.c1 .react-calendar__tile--active {
  background-color: #22a2ff;
  color: white;
}
.c1 .react-calendar__tile :hover {
  color: white;
}
.c1 .react-calendar__month-view__weekdays__weekday {
  color: #9c9da0;
  font-size: 10px;
  font-family: 14px, "Proxima Nova";
}
.c1 .react-calendar__tile:disabled {
  background-color: white;
  pointer-events: none;
}

@media only screen and (max-width: 600px) {
  div .content-select-calendar {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
  }
  div .container-selecter {
    display: flex;
    flex: 1;
    margin-top: 0vh;
    justify-content: center;
    align-self: center;
  }
  div .c1 {
    width: 90vw !important;
    height: 75vh;
  }
  .style_box {
    width: "100vw";
    overflow-y: "hidden";
    overflow-x: "hidden";
  }
  div .txt-event-type-selected {
    color: #4d5055;
    font-family: 14px, "Proxima Nova";
    font-size: 14px;
  }
  div .txt-date-selected {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 18px;
    align-self: center;
    color: #4d5055;
    font-family: 14px, "Proxima Nova";
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }
  div .txt-selected-name {
    margin-left: 10px;
    color: #4d5055;
    font-family: 14px, "Proxima Nova";
    font-size: 14px;
    font-weight: 250;
    line-height: 24px;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  div .menu-left {
    display: flex;
    flex-direction: column;
    border-right: 0 !important;
    border-bottom: 0.1px solid #ededee;
  }
  div .menu-rigth {
    display: block;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-top: 0;
  }
  div .txt-name-selected {
    color: rgba(77, 80, 85, 0.6);
    font-family: 14px, "Proxima Nova";
    font-size: 16px;
  }
  div .react-calendar {
    margin: 0;
    padding: 0;
  }
  .c1 {
    font-size: 16px;
    font-family: 14px, "Proxima Nova";
    color: #4d5055;
    background-color: "red";
  }
  ::-webkit-scrollbar {
    width: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .btn-rounder-back {
    height: 40px;
    width: 40px;
    background-color: white;
    border: 1px solid #ededee;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
